/* mission-popup style */
.mission-popup-box {
    position: fixed;
    width: 20vw;
    height: auto;
    max-height: 70vh;
    top: 20vh;
    right: 1vw;
    text-transform: none;
    transition: all 250ms linear 1s;
    
  }
  .mission-popup-box .mission-box{
    transition: all 250ms linear 1s;
  }
  .mission-rightboard{
    /* float:right; */
    margin: auto;
    padding:1px;
    max-width: 100%;
    background: #2941bc;
    /* display:inline; */
    border:1px solid white;
    font-size: 14px;
  }
  .mission-rightboard ul {
    list-style: none;
    text-align: left;
    max-width: 100%;
    padding: 0%;
  }
  .mission-box {
    text-transform: none;
    position: relative;
    margin: auto;
    height: 100%;
    background: transparent;
    color: white;
    border-radius: 4px;
    padding: 1px;
    overflow: auto;
    line-height: 1;
    font-size: 12px;
    width:100%;
    margin-top: 3vh;
    max-height: 50vh;
    overflow-y: auto;
  }
  .mission-box ul {
    list-style: none;
    text-align: left;
    max-width: 100%;
    padding: 0%;
  }
  .mission-box ul li {
    /* float:right; */
    margin: auto;
    margin-top: 5px;
    padding:5px;
    max-width: 100%;
    background: #2941bc;
    /* display:inline; */
    border:1px solid white;
  }
  .mission-box ul li.new {
    /* float:right; */
    margin: auto;
    margin-top: 5px;
    padding:5px;
    max-width: 100%;
    background: #bc5829;
    /* display:inline; */
    border:1px solid white;
  }
  .missionNote-icon {
    width: 2vh;
    height: 2vh;
    cursor: pointer;
    position: fixed;
    color: white;
    right: calc(5vw);
    top: calc(18vh);
    font-size: 16px;
    line-height: 1;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    z-index: 6;
    background: #bc2929;
  }
  .mission-close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(1vw + 20vw);
    top: calc(25vh);
    background: #2941bc;
    width: 3vh;
    color: white;
    height: 3vh;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 16px;
    line-height: 1;
  }
  .mission-icon {
    position: fixed;
    right: calc(1vw);
    top: calc(18vh);
    background: #2941bc;
    width: 5vh;
    font-size: 10px;
    color: white;
    height: 7vh;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 16px;
    line-height: 1;
    z-index: 3;
  }
  .mission-box h1, .mission-box h2, .mission-box h3, .mission-box h4{
    font-size: 14px;
    padding-right: 5vw;
    background: #2941bc;
  }

  @media only screen and (max-width: 1200px) {
    .mission-popup-box {
      position: fixed;
      width: auto;
      max-width: 30vw;
      max-height: 20vh;
      top: 20vh;
      right: 1vw;
    }
    .mission-box {
        position: relative;
        margin: 0 auto;
        height: auto;
        border-radius: 4px;
        padding: 1px;
        overflow: auto;
        font-size: 10px;
      }
      .mission-box h1, .mission-box h2, .mission-box h3, .mission-box h4{
        font-size: 10px;
        padding-right: 10vw;
      }
      .mission-close-icon {
        content: 'x';
        cursor: pointer;
        position: fixed;
        right: calc(1vw + 30vw);
        top: calc(18vh);
        background: #2941bc;
        width: 3vh;
        font-size: 10px;
        color: white;
        height: 3vh;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 10px;
        line-height: 1;
        
      }
      .missionNote-icon {
        width: 2vh;
        height: 2vh;
        cursor: pointer;
        position: fixed;
        color: white;
        right: calc(5vw);
        top: calc(18vh);
        font-size: 10px;
        line-height: 1;
        border-radius: 50%;
        line-height: 5px;
        text-align: center;
        border: 1px solid #999;
        z-index: 6;
        background: #bc2929;
      }
      .mission-icon {
        width: 5vh;
        height: 5vh;
        right: calc(1vw);
        top: calc(18vh);
      }
     .mission-rightboard{
      font-size: 10px;
     }
  }