/* Popup style */
.popup-box {
    position: fixed;
    background: #ffffff60;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    text-transform: none;
    z-index: 99999;
  }
   
  .box {
    text-transform: none;
    position: relative;
    width: 50vw;
    margin: 0 auto;
    height: auto;
    max-height: 50vh;
    margin-top: calc(100vh - 65vh - 20px);
    background: #312e4e60;
    color: white;
    border-radius: 4px;
    padding: 16px;
    border: 1px solid #999;
    overflow: auto;
    line-height: 1;
  }
   
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(25vw - 20px);
    top: calc(100vh - 65vh - 33px);
    background: #312e4e;
    width: 25px;
    font-size: 16px;
    color: white;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 16px;
    line-height: 1;
  }

  @media only screen and (max-width: 1200px) {
    .box {
        position: relative;
        width: 50vw;
        margin: 0 auto;
        height: auto;
        max-height: 50vh;
        margin-top: calc(100vh - 65vh - 20px);
        background: #312e4e60;
        border-radius: 4px;
        padding: 5px;
        border: 1px solid #999;
        overflow: auto;
        font-size: 10px;
      }
      .box h1, .box h2, .box h3, .box h4{
        font-size: 12px;
      }
      .close-icon {
        content: 'x';
        cursor: pointer;
        position: fixed;
        color: white;
        right: calc(25vw - 20px);
        top: calc(100vh - 65vh - 33px);
        background: #312e4e;
        width: 25px;
        font-size: 10px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 20px;
        
      }
  }