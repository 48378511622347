/* Popup style */
.popup-loading {
    background-size: cover !important;
    position: absolute;
    background: #29252560;
    width: 100vw;
    height: 100vh;
    top: -50vh;
    left: -50vw;
    text-transform: none;
  }
  
.popup-loading .loading {
    position: absolute;
    background: #29252560;
    top: 90vh;
    left:10vw;
    width: 10vw;
    color: white;
    background-color: #29252560;
  }