
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  box-sizing: border-box;
}

html,
body,
#root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #00000050;
  background-color: #00000050;
  overflow: hidden; 
}

.checkbox {
  position: absolute;
  z-index: 5;
  font-size: 16;
  padding: 6px;
  top: calc(10vh);
  left: calc(1vw);
  background: #1a1a1a50;
  color: #eeeeee;
}

.checkbox > input[type='checkbox'] {
  vertical-align: middle;
  text-transform: uppercase;
}
@media only screen and (max-width: 1200px) {
  .checkbox {
    position: absolute;
    z-index: 5;
    font-size: 10px;
    padding: 3px;
    background: #1a1a1a50;
    color: #eeeeee;
  }
  .checkbox h1, .checkbox h2, .checkbox h3, .checkbox h4{
    font-size: 12px;
  }
  .checkbox input, .checkbox button  {
    font-size: 10px;
    padding: 1px;
    vertical-align: middle;
  }
}

.stocks{
  max-height: 30vh;
  overflow-y: auto !important;
  background-color: #4436c560;
}
.stocks button{
  position: absolute;
  z-index: 99;
  top: 31vh;
  left: 0;
}

/* CSS */
button {
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 10px,rgba(44, 187, 99, .15) 0 10px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

button:hover {
  box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 10px,rgba(44,187,99,.25) 0 10px 16px,rgba(44,187,99,.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
  color: white;
  background-color: #181a1970;
}
button:disabled{
  color: white;
  background-color: #6d111170;
  cursor: default;
  box-shadow: none;
  transform: none;
}
button:disabled:hover{
  box-shadow: none;
  transform: none;
  color: white;
  background-color: #6d111170;
}
@media only screen and (max-width: 1200px) {
  button {
    font-size: 10px !important;
    padding: 4px 12px;
  }
}


/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #38bc2980;
  border-radius: 12px;
}